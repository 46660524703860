import React, { useEffect, useState } from 'react';
import fetchMethodRequest from '../../config/service';
// Toaster message
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import validate from '../Validations/validate';
// Loader
import Loader from '../App/Loader';
// Calendar
//session expiry modal
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
import { th } from 'date-fns/locale';
import store from '../App/store';
import DoInputField from '../Form/Fields/DoInputField';
import DoRadioButtons from '../Form/Fields/DoRadioButtons';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';


const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
const SettingsForm = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [schema, setSchema] = useState({});
  const [options, setOptions] = useState(
    [
      { label: 'ddMMYYY', value: 'DD/MM/YYYY' },
      { label: 'MMDDYYY', value: 'MM/DD/YYYY' },
      { label: 'MMM DD YYYY', value: 'MMM DD YYYY' },
      { label: 'DD MMM YYYY', value: 'DD MMM YYYY' },
      { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
      { label: 'MM/DD/YYYY HH:mm A', value: 'MM/DD/YYYY HH:mm A' },
      { label: 'MMM DD YYYY HH:mm A', value: 'MMM DD YYYY HH:mm A' },
      { label: 'hh:mm A, MM-DD-YYYY', value: 'hh:mm A, MM-DD-YYYY' },
      { label: 'MM/DD/YYYY HH:mm', value: 'MM/DD/YYYY HH:mm' },
      { label: 'YYYY-MM-DD HH:mm:ss', value: 'YYYY-MM-DD HH:mm:ss' },
      { label: 'YYYY-MM-DD[T]HH:mm:ss.SSS', value: 'YYYY-MM-DD[T]HH:mm:ss.SSS' },
      { label: 'YYYY-MM-DD[T]00:00:00Z', value: 'YYYY-MM-DD[T]00:00:00Z' },
      { label: 'MMM YYYY', value: 'MMM YYYY' },
      { label: 'MMM  DD, YYYY', value: 'MMM  DD, YYYY' },
    ]
  );
  const VerificationOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]
  const emailSorceOptions = [
    { label: 'Node Mailer', value: 'nodeMailer' },
    { label: 'Send Grid', value: 'sendgrid' },
  ]
  const enableMailOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]
  const disableLoginOpt = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ]



  useEffect(() => {
    getSettingsData();
  }, [])

  let {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const watchEmailSourceType = watch('emailSourceType', 'sendGrid');


  const getSettingsData = () => {
    setIsLoading(true);
    let filterCriteria = {};
    filterCriteria['criteria'] = [];
    fetchMethodRequest('GET', `settings?filter=${JSON.stringify(filterCriteria)}`).then(async (response) => {
      if (response && response.respCode && response.settings && response.settings[0]) {
        setFormValues({ ...response.settings[0] })

      }
    })
  }

  const setFormValues = (settings) => {
    if (settings.Admin && typeof settings.Admin === 'object') {
      setValue('GoogleClientId', settings.Admin.GoogleClientId);
      setValue('GooglesecretKey', settings.Admin.GooglesecretKey);
    }
    setValue('sendGridApiKey', settings.sendGridApiKey);
    setValue('sendGridEmail', settings.sendGridEmail);
    setValue('emailSourceType', settings.emailSourceType);
    setValue('enableMails', settings.enableMails);
    setValue('disableMultipleLogin', settings.disableMultipleLogin);
    setValue('nodeMailerHost', settings.nodeMailerHost);
    setValue('nodeMailerPass', settings.nodeMailerPass);
    setValue('nodeMailerUser', settings.nodeMailerUser);
    setValue('expireTokenTimeInMin', settings.expireTokenTimeInMin);
    setValue('adminExpireTokenTimeInMin', settings.adminExpireTokenTimeInMin);
    setValue('isTwoFactorAuthentication', settings.isTwoFactorAuthentication);
    setValue('otpExpiryTimeInMin', settings.otpExpiryTimeInMin);
    setValue('_id', settings._id);
    setIsLoading(false);
  }

  const onUpdate = (data) => {
    let formData = { ...data };
    formData.Admin = {};
    formData.Admin.GoogleClientId = data.GoogleClientId;
    formData.Admin.GooglesecretKey = data.GooglesecretKey;
    if (data.emailSourceType === 'nodeMailer') {
      delete formData.sendGridApiKey;
    } else {
      delete formData.nodeMailerHost;
      delete formData.nodeMailerPass;
      delete formData.nodeMailerUser;
    }
    delete formData.GoogleClientId;
    delete formData.GooglesecretKey;
    console.log(formData);
    saveDataToServer(formData);
  }




  //send data to server
  const saveDataToServer = (formValues) => {
    setIsLoading(true);
    if (formValues) {
      let method, apiUrl;
      apiUrl = `settings/${formValues._id}`
      fetchMethodRequest('PUT', apiUrl, formValues)
        .then((response) => {
          setIsLoading(false);
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, 'success');
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }

        })
    }
  }

  const apple = () => {
    console.log('I am refreshed')
  }

  let isSubmitting = false

  return (
    <div style={{ overflowX: 'hidden' }} onLoad={apple}>
      <Loader loader={isLoading} />
      <form onSubmit={handleSubmit(onUpdate)}>
        <div className='px-5 py-2 settings-content'>

          <Card title='Mail Settings'>
            <div className='row row-col-12'>
              <div className='d-flex col-6'>
                <div className='d-flex col-md-6'>
                  <Controller
                    name={'emailSourceType'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoRadioButtons
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        options={emailSorceOptions}
                        fieldState={fieldState}
                        errors={errors}
                        label={'Email Source Type'}
                      />)}
                  />
                </div>

                <div className='d-flex col-md-6'>
                  <Controller
                    name={'enableMails'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoRadioButtons
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        options={enableMailOptions}
                        fieldState={fieldState}
                        errors={errors}
                        label={'Enable Mails'}
                      />)}
                  />
                </div>

              </div>
              {watchEmailSourceType === 'sendgrid' &&
                <div className='d-flex col-6 gap-2'>
                  <div className='col-md-6'>
                    <Controller
                      name={'sendGridApiKey'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoInputField
                          input={field}
                          id={field.id}
                          name={field.name}
                          label={'Send Grid Api Key'}
                          field={field}
                          type={"text"}
                          fieldState={fieldState}
                          errors={errors}
                          placeholder={'Send Grid Api Key'}
                        />
                      )}
                    />
                  </div>
                  <div className='col-md-6'>
                    <Controller
                      name={'sendGridEmail'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoInputField
                          input={field}
                          id={field.id}
                          name={field.name}
                          label={'Send Grid Email'}
                          field={field}
                          type={"text"}
                          fieldState={fieldState}
                          errors={errors}
                          placeholder={'Send Grid Email'}
                        />
                      )}
                    />
                  </div>
                </div>
              }
            </div>

            {watchEmailSourceType === 'nodeMailer' &&
              <div className='row row-cols-4 mt-4'>
                <Controller
                  name={'nodeMailerHost'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'Smtp host'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'Smtp Host'}
                    />)}
                />
                <Controller
                  name={'nodeMailerUser'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'SMTP user'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'SMTP user'}
                    />)}
                />
                <Controller
                  name={'nodeMailerPass'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'SMTP password'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'SMTP password'}
                    />)}
                />
                <Controller
                  name={'sendGridEmail'}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DoInputField
                      input={field}
                      id={field.id}
                      name={field.name}
                      label={'Nodemailer Email'}
                      field={field}
                      type={"text"}
                      fieldState={fieldState}
                      errors={errors}
                      placeholder={'Nodemailer Email'}
                    />)}
                />
              </div>
            }
          </Card>

          <Card title='Token Time Settings' className='mt-3'>
            <div className='row row-cols-4'>
              <Controller
                name={'expireTokenTimeInMin'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={'Expire Token Time (min)'}
                    field={field}
                    type={"text"}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder={'Expire Token Time'}
                  />)}
              />
              <Controller
                name={'adminExpireTokenTimeInMin'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={'Admin Expire Token Time (min)'}
                    field={field}
                    type={"text"}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder={'Admin Expire Token Time'}
                  />)}
              />
              <div>
                <div className='d-flex'>
                  <Controller
                    name={'disableMultipleLogin'}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoRadioButtons
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        options={disableLoginOpt}
                        fieldState={fieldState}
                        errors={errors}
                        label={"Disable Multiple Login"}
                      />)}
                  />
                </div>
              </div>
            </div>
          </Card>

          <Card title='Google Settings' className='mt-3'>
            <div className='row row-cols-2'>
              <Controller
                name={'GoogleClientId'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={'Admin Google Client ID(Login)'}
                    field={field}
                    type={"text"}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder={'Admin Google Client ID'}
                  />)}
              />
              <Controller
                name={'GooglesecretKey'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={'Admin Google Secret Key(Recaptcha)'}
                    field={field}
                    type={"text"}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder={'Admin Google Secret Key'}
                  />)}
              />
            </div>
          </Card>

          <Card title='Two-Factor Authentication Settings' className='mt-3'>
            <div className='row row-cols-2'>
              <Controller
                name={'isTwoFactorAuthentication'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoRadioButtons
                    input={field}
                    id={field.id}
                    name={field.name}
                    field={field}
                    options={VerificationOptions}
                    fieldState={fieldState}
                    errors={errors}
                    label={'TwoFactor Authentication Required'}
                  />)}
              />
              <Controller
                name={'otpExpiryTimeInMin'}
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={'Resend OTP Time(min)'}
                    field={field}
                    type={"text"}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder={'OTP Time'}
                  />)}
              />
            </div>
          </Card>

          <div className='d-flex mt-5 mb-5 justify-content-end'>
            <Button color='primary' size="sm" type='submit'>Update</Button>
          </div>

        </div>
      </form>
    </div>
  );
}
export default SettingsForm;