import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../App/Loader';
import fetchMethodRequest from '../../config/service';
import { Chart } from 'primereact/chart';
import { Controller, useForm } from 'react-hook-form';
import DoDateField from '../Form/Fields/DoDateField';
import DoAutoCompleteField from '../Form/Fields/DoAutoCompleteField';
import config from '../../config/config';
import dateFormats from '../UI/FormatDate/formatDate';
import moment from 'moment';

const Dashboard = () => {
  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm();

  const fetchDashboardData = (values) => {
    let user = "";
    if (values) {
      values.date = dateFormats.formatDate(values.date, config.dateYearMonthFormat);
      user = values.userName.userName;
    }

    fetchMethodRequest('POST', `userActivities/getUserActivities?userName=${encodeURIComponent(user)}&date=${encodeURIComponent(values?.date)}`)
      .then(async (response) => {
        if (response) {
          initializeChart(response.result);
        }
      }).catch((err) => {
        return err;
      });
  };

  const initializeChart = (res) => {
    let timeDiff = 300000;
    let tolerance = 5000;

    const labels = [];
    const dataPoints = [];
    const backgroundColors = [];
    const borderColors = [];

    // Loop through each item and check the time difference
    res.forEach((item, index) => {
      const currentTime = moment.utc(item['onDate']).local();
      const formattedTime = currentTime.format('HH:mm');

      // if (index > 0) {
      //   const previousTime = moment.utc(res[index - 1]['onDate']).local();
      //   const diff = currentTime.diff(previousTime);

      //   // Insert a gap if the time difference exceeds the tolerance around 5 minutes
      //   if (Math.abs(diff) > timeDiff + tolerance) {
      //     labels.push("");
      //     dataPoints.push(null);
      //     backgroundColors.push("transparent");
      //     borderColors.push("transparent");
      //   }
      // }

      labels.push(formattedTime);
      dataPoints.push(20);
      backgroundColors.push(item.colorCode);
      borderColors.push(item.colorCode);
    });

    // Chart.js data and options
    const data = {
      labels,
      datasets: [
        {
          label: 'Employee Activity',
          data: dataPoints,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const index = tooltipItem.dataIndex;
              const item = res[index];
              return `${item.windowName}: ${item.windowTitle}`;
            },
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  };

  const submit = (values) => {
    fetchDashboardData(values);
  }


  return (
    <Container className="dashboard ml-5" style={{ width: '98%' }}>
      <Loader loader={isLoading} />

      <Row>
        <Col md={12}>
          <h3 className="page-title mb-1 fw-bold mb-3">{t('DashBoard')}</h3>
        </Col>
      </Row>

      <Col className='col-12'>
        <Card>
          <CardBody>
            <Row>
              <form className="form" onSubmit={handleSubmit(submit)}>
                <div className='d-flex gap-5'>
                  <Controller
                    name={"userName"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoAutoCompleteField
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        multiple={false}
                        fieldState={fieldState}
                        errors={errors}
                        searchApi={"userActivities/listUsers"}
                        searchField={'userName'}
                        placeholder={"User"}
                        label={"User"}
                        getValues={getValues}
                      />)}
                  />
                  <Controller
                    name={"date"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DoDateField
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        fieldState={fieldState}
                        errors={errors}
                        placeholder="Date"
                      />)}
                  />
                  <Button className="ml-auto fixed-btn-height" color="primary" >Show Chart</Button>
                </div>
              </form>
            </Row>
            <Row>
              <div className="">
                <Chart type="bar" data={chartData} options={chartOptions} />
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>

    </Container>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;