const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  useractivities:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_useractivities_bulk_upload_file.csv",
  useractivitysummaries:
    "http://api.screenmonitoring.dosystemsinc.com/images/sample_useractivitysummaries_bulk_upload_file.csv",
};
export default configSampleFiles;
